.education__stepper{
    
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    background-color: #fafafa;
}
.education__step{
    width: 28%;
    height: 20%;
    margin: 8px;
}
.app__testimonial {
    flex: 1;
    width: 100%;
    flex-direction: column;
  }
  
.app__testimonial-item {
width: 100%;
min-height: 320px;
background-color: var(--white-color);
display: flex;
flex-direction: row;
padding: 2rem;
border-radius: 15px;
box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);

transition: all 0.3s ease-in-out;

img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
}

@media screen and (min-width: 2000px) {
    min-height: 450px;

    img {
    width: 150px;
    height: 150px;
    }
}

@media screen and (max-width: 850px) {
    width: 100%;
}

@media screen and (max-width: 600px) {
    flex-direction: column;
}
}

.app__testimonial-content {
flex: 1;
height: 100%;
padding: 0 2rem;
text-align: left;

display: flex;
flex-direction: column;
justify-content: space-around;
align-items: flex-start;

p {
    font-size: 1.25rem;
    line-height: 2rem;
    color: var(--black-color);
    font-family: var(--font-base);

    @media screen and (min-width: 2000px) {
    font-size: 2rem;
    line-height: 3.5rem;
    }
}
h4 {
    font-weight: 600;
    color: var(--secondary-color);
    margin-top: 2rem;
    }

h5 {
    font-weight: 400;
    color: var(--gray-color);
    margin-top: 5px;
    }

    @media screen and (max-width: 600px) {
    margin-top: 2rem;
    padding: 0;
    }
}
.app__work-portfolio {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  
    .app__work-item {
      width: 270px;
      flex-direction: column;
  
      margin: 2rem;
      padding: 1rem;
      border-radius: 0.5rem;
      background-color: #fff;
      color: #000;
  
      cursor: pointer;
      transition: all 0.3s ease;
  
      &:hover {
        box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
      }
  
      @media screen and (min-width: 2000px) {
        width: 470px;
        padding: 1.25rem;
        border-radius: 0.75rem;
      }
  
      @media screen and (max-width: 300px) {
        width: 100%;
        margin: 1rem;
      }
    }
  }
  
  .app__works {
    flex: 1;
    width: 100%;
    flex-direction: column;
  }
  
  .app__work-img {
    width: 100%;
    height: 230px;
  
    position: relative;
  
    img {
      width: 100%;
      height: 100%;
      border-radius: 0.5rem;
      object-fit: cover;
    }
  
    @media screen and (min-width: 2000px) {
      height: 350px;
    }
  }
  
  .app__work-hover {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  
    border-radius: 0.5rem;
    opacity: 0;
    transition: all 0.3s ease;
  
    div {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.5);
      color: #fff;
  
      margin: 1rem;
      font-family: var(--font-base);
      font-weight: 800;
      cursor: pointer;
      transition: all 0.3s ease;
  
      svg {
        width: 50%;
        height: 50%;
        color: var(--white-color);
      }
    }
  }
  
  .app__work-content {
    padding: 0.5rem;
    width: 100%;
    position: relative;
    flex-direction: column;
  
    h4 {
      margin-top: 1rem;
      line-height: 1.5;
  
      @media screen and (min-width: 2000px) {
        margin-top: 3rem;
      }
    }
  
    .app__work-tag {
      position: absolute;
  
      padding: 0.5rem 1rem;
      border-radius: 10px;
      background-color: #fff;
      top: -25px;
    }
  }
  .education-sub-container{
    display: flex;
    
  }
 
  .education-container{
    display: flex;
    flex-direction: column;
    
    align-items: center;
  }